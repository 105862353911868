import { useState, SyntheticEvent } from 'react'
import { styled } from '@mui/material/styles'
import {
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Fade,
  ClickAwayListener,
} from '@mui/material'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = (e: SyntheticEvent) => {
    e.stopPropagation()
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Typography component="div" variant="body2">
        <Tooltip
          {...props}
          PopperProps={{
            disablePortal: true,
          }}
          onClick={handleTooltipOpen}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          classes={{ popper: className }}
        />
      </Typography>
    </ClickAwayListener>
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.97)',
    maxWidth: 282,
    padding: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[6],
  },
}))

export default HtmlTooltip
