type ClaimBetaTokenResponse = {
  success: boolean,
  errorKey: 'unknown_error' | 'bad_request' | 'exceed_airdrop_amount' | 'recaptcha_failed',
  error: string,
  txSig: string
}

export const claimBetaToken = async (
  walletAddress: string,
  captchaToken: string | null | undefined,
): Promise<ClaimBetaTokenResponse> => {
  const response = await fetch('/api/beta-airdrop', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      receiverAddress: walletAddress,
      captchaToken,
    }),
  })
  const { errorKey, error, txSig } = await response.json()
  return { success: response.ok, errorKey, error, txSig }
}
