import React, { memo, useCallback, useMemo, useState } from 'react'
import { AppBar, Container, SwipeableDrawer } from '@mui/material'

import MainMenu from '../components/MainMenu'
import PropEasyLogo from '@/components/base/PropEasyLogo'
import PropEasyMenu from '@/components/base/PropEasyMenu'
import PropEasyAction from '@/components/base/PropEasyAction'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'
import withNoSsr from '@/components/shared/with-no-ssr'

const Header = () => {
  const isMobile = useIsMobileDevice()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setIsOpenMenu(open)
    },
    [],
  )

  const handleClickMenu = useCallback(() => {
    setIsOpenMenu(true)
  }, [setIsOpenMenu])

  const actionProps = useMemo(() => {
    return { isMobile, handleClickMenu }
  }, [isMobile, handleClickMenu])

  const drawer = useMemo(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={isOpenMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={15}
        hysteresis={1}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <MainMenu toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    ),
    [isOpenMenu, toggleDrawer],
  )
  return (
    <>
      <AppBar color="primary" className="shadow-none z-10" position="static">
        <Container className="px-2 py-4 max-h-[4rem] flex items-center justify-between">
          <PropEasyLogo iconOnly={isMobile} />

          {!isMobile && <PropEasyMenu />}

          <PropEasyAction {...actionProps} />
        </Container>
      </AppBar>
      {isMobile && drawer}
    </>
  )
}

export default memo(withNoSsr(Header))
