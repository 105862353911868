import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { useWalletModal } from '../hooks'
import { useWallet } from '@solana/wallet-adapter-react'
import { useFetchKycVerifications, useUpdateReferralAddress } from '@/hooks/use-kyc-verifications'
import { minifyAddress } from '@/utils/helpers'

import Image from 'next/image'
import WalletProfileIcon from '@/assets/wallet-profile-icon.png'
import copy from 'copy-to-clipboard'

interface WalletAddressProps {
  address?: string
  isVerified?: boolean
}

function WalletAddress(props: WalletAddressProps) {
  const { address, isVerified } = props
  const { publicKey, wallet } = useWallet()
  const { trans } = useWalletModal()
  const [copied, setCopied] = useState(false)

  const updateReferralAddress = useUpdateReferralAddress()
  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])
  const content = useMemo(() => {
    if (!wallet || !base58) return null
    return minifyAddress(address ? address : base58)
  }, [wallet, base58, address])

  useEffect(() => {
    if (base58) updateReferralAddress.mutate(base58)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base58])
  const { data } = useFetchKycVerifications(base58)

  const copyAddress = useCallback(() => {
    if (base58) {
      const dataAddress = address ? address : base58
      copy(dataAddress, { message: 'Copied' })
      setCopied(true)
      setTimeout(() => setCopied(false), 600)
    }
  }, [base58, address])

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {!address && (
        <Image
          className="rounded-full"
          src={WalletProfileIcon}
          height={28}
          alt="wallet profile icon"
        />
      )}

      <Typography className="font-semibold text-black/90">
        {copied ? trans.copied : content}
        {isVerified && data?.kyc_completed && (
          <Image
            className="inline ml-1"
            src={staticImportIcon(IconName.VERIFIED_ICON)}
            height={24}
            alt={IconName.VERIFIED_ICON}
          />
        )}
      </Typography>
      <IconButton
        disableRipple
        className="inline p-0"
        onClick={copyAddress}
        title={trans.copy_address}
      >
        <Image src={staticImportIcon(IconName.COPY_ICON)} height={24} alt={IconName.COPY_ICON} />
      </IconButton>
    </Stack>
  )
}

export default WalletAddress
