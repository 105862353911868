import React, { memo, useCallback, useMemo, useState } from 'react'
import { Portal } from '@mui/base'
import { Box, IconButton, InputBase, Stack, Typography, Snackbar } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { track } from '@/utils/amplitude'
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '../hooks'

import Image from 'next/image'
import LaunchReferralImage from '@/assets/launch-referral-image.png'
import copy from 'copy-to-clipboard'
import { PROPEASY_SITE_URL } from '@/utils/helpers'

interface WalletShareProps {
  url?: string
}

function WalletShare(props: WalletShareProps) {
  const { url } = props
  const [openCopiedSnack, setOpenCopiedSnack] = useState(false)
  const { trans } = useWalletModal()
  const { publicKey, wallet } = useWallet()
  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])

  const urlShare = useMemo(() => {
    if (url) return url
    if (!wallet || !base58) return ''
    return `${PROPEASY_SITE_URL}?referral_code=${base58}`
  }, [wallet, base58, url])

  const handleShareClick = useCallback(() => {
    track('click_referral_share_button')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const copyAddress = useCallback(() => {
    if (urlShare) {
      copy(urlShare, { message: 'copied' })
      setOpenCopiedSnack(true)
      handleShareClick()
    }
  }, [handleShareClick, urlShare])

  const boxShare = useMemo(() => {
    return (
      <Stack
        className="p-4 bg-gradient-to-r from-[#F1F0F0]/[0.38] to-[#F9F2EE]/[0.98] w-full"
        alignItems="center"
        gap={1}
      >
        {!url && (
          <Image className="text-[#6C6B6A]" src={LaunchReferralImage} width={75} alt="notify" />
        )}
        <Typography className="font-semibold">{trans.share_my_referral}</Typography>
        <Stack
          className="w-full rounded-lg border border-solid border-[#425066]/[0.40] px-3 py-2 "
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <InputBase fullWidth readOnly className="border-none ml-1 pr-2" value={urlShare} />
          <IconButton
            className="inline rounded-lg bg-ps-primary hover:bg-ps-dark-blue"
            onClick={copyAddress}
          >
            <Image
              src={staticImportIcon(IconName.COPY_LIGHT_ICON)}
              height={24}
              alt={IconName.COPY_LIGHT_ICON}
            />
          </IconButton>
        </Stack>
        <Typography className="my-3">{trans.share_other}</Typography>
        <Stack direction="row" gap={3}>
          <FacebookShareButton url={urlShare} onClick={handleShareClick}>
            <Image
              src={staticImportIcon(IconName.FACEBOOK_DARK_ICON)}
              alt={IconName.FACEBOOK_DARK_ICON}
              width={32}
            />
          </FacebookShareButton>
          <TwitterShareButton url={urlShare} onClick={handleShareClick}>
            <Image
              src={staticImportIcon(IconName.TWITTER_DARK_ICON)}
              alt={IconName.TWITTER_DARK_ICON}
              width={32}
            />
          </TwitterShareButton>
          <TelegramShareButton url={urlShare} onClick={handleShareClick}>
            <Image
              src={staticImportIcon(IconName.TELEGRAM_DARK_ICON)}
              alt={IconName.TELEGRAM_DARK_ICON}
              width={32}
            />
          </TelegramShareButton>
        </Stack>
      </Stack>
    )
  }, [trans, url, urlShare, copyAddress, handleShareClick])

  return (
    <>
      {url ? (
        <Stack alignItems="center">
          <Image className="text-[#6C6B6A]" src={LaunchReferralImage} width={137} alt="notify" />
          <Typography className="text-center text-[#212527] text-2xl font-bold">
            {trans.share}
          </Typography>
          <Typography
            className="text-[#3A3529] text-center px-3 py-4"
            dangerouslySetInnerHTML={{ __html: trans.share_notice }}
          />
          {boxShare}
        </Stack>
      ) : (
        <Box className="rounded-lg border border-[#798297]/[0.10] text-[#0F0E0C]">
          <Typography
            className="bg-[#F3F3F3] text-[#3A3529] px-3 py-4"
            dangerouslySetInnerHTML={{ __html: trans.share_notice }}
          />
          {boxShare}
        </Box>
      )}

      <Portal>
        <Snackbar
          message={trans.copied}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={() => setOpenCopiedSnack(false)}
          open={openCopiedSnack}
        />
      </Portal>
    </>
  )
}

export default memo(WalletShare)
