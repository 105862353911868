import React, { useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@/wallet/hooks'
import WalletIcon from '@mui/icons-material/Wallet'
import { CircularProgress, IconButton, Stack } from '@mui/material'
import { claimBetaToken } from '@/utils/apis/beta'
import useToastMessage from '@/hooks/use-toast-message'
import ReCAPTCHA from 'react-google-recaptcha'

const WalletClaimBetaToken = () => {
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
  const { publicKey } = useWallet()
  const toast = useToastMessage()
  const { trans } = useWalletModal()
  const [loading, setLoading] = useState(true)
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  const handleClaimToken = async () => {
    if (!publicKey) return
    setLoading(true)

    const token = await recaptchaRef.current?.executeAsync()
    const { success, errorKey, error } = await claimBetaToken(publicKey.toString(), token)
    if (success) {
      toast.showMessage({ message: trans.beta.airdrop_success, type: 'success' })
    } else {
      toast.showMessage({ message: trans.beta.airdrop_error[errorKey], type: 'error' })
      // eslint-disable-next-line no-console
      console.error(error)
    }
    setLoading(false)
  }

  const onCaptchaLoaded = () => setLoading(false)

  return (
    <>
      <Stack sx={{ display: loading ? 'inherit' : 'none' }}>
        { recaptchaSiteKey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaSiteKey ?? ''}
            asyncScriptOnLoad={onCaptchaLoaded}
            badge="inline"
          />
        )}
      </Stack>
      <IconButton
        disableRipple
        className="w-full bg-ps-primary hover:bg-ps-dark-blue font-semibold text-base text-ps-secondary normal-case rounded-full px-5 py-2 gap-2"
        onClick={handleClaimToken}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <>
            <WalletIcon/>
            {trans.beta.claim_token_test}
          </>
        )}

      </IconButton>
    </>
  )
}

export default WalletClaimBetaToken
