import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Drawer } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'
import { Button, type ButtonProps } from './Button'
import { WalletModalButton } from './WalletModalButton'
import WalletAddress from './WalletAddress'
import WalletProfile from './WalletProfile'
import { setTrackingUser } from '../../utils/amplitude'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'

export const WalletMultiButton: FC<ButtonProps> = ({
  children,
  listMenuItems,
  onlyAddress,
  hideButton,
  ...props
}) => {
  const { publicKey, wallet } = useWallet()
  const [active, setActive] = useState(false)
  const [hasUserId, setHasUserId] = useState(false)
  const isMobileDevice = useIsMobileDevice()
  const ref = useRef<HTMLUListElement>(null)

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])
  const content = useMemo(() => {
    if (children) return children
    if (!wallet || !base58) return null
    return base58.slice(0, 4) + '...' + base58.slice(-4)
  }, [children, wallet, base58])

  const openDropdown = useCallback(() => {
    setActive(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setActive(false)
  }, [])

  // const openModal = useCallback(() => {
  //   setVisible(true)
  //   closeDropdown()
  // }, [setVisible, closeDropdown])

  useEffect(() => {
    if (publicKey && !hasUserId) {
      setTrackingUser(publicKey.toBase58())
      setHasUserId(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey])

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return

      closeDropdown()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, closeDropdown])

  if (!wallet || !base58)
    return (
      <div className="wallet-adapter-button-wrap">
        <WalletModalButton {...props}>{children}</WalletModalButton>
      </div>
    )

  delete props.className
  if (hideButton) return <></>
  if (onlyAddress) {
    return <WalletAddress isVerified={onlyAddress} />
  }

  return (
    <div className="wallet-adapter-button-wrap">
      <Button
        aria-expanded={active}
        className="wallet-adapter-button-trigger bg-[#2B5B74] hover:bg-[#27536B] rounded-[1.5rem] border-solid border border-[#4E768B] text-sm font-bold text-[#FFFFFF]/[0.90] mr-1"
        style={{ pointerEvents: active ? 'none' : 'auto', ...props.style }}
        onClick={openDropdown}
        beginIcon={
          <Image
            className="rounded-full"
            src={staticImportIcon(IconName.WALLET_ICON)}
            height={24}
            alt="wallet profile icon"
          />
        }
        {...props}
      >
        {content} <KeyboardArrowDownIcon />
      </Button>
      {!isMobileDevice ? (
        <Box
          aria-label="dropdown-list"
          className={`wallet-adapter-dropdown-list ${
            active && 'wallet-adapter-dropdown-list-active'
          }`}
          role="menu"
          ref={ref}
        >
          {listMenuItems}
          <WalletProfile closeDropdown={closeDropdown} />
        </Box>
      ) : (
        <Drawer
          anchor="bottom"
          className="z-[1660] rounded-t-2xl"
          open={active}
          onClose={closeDropdown}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '1rem 1rem 0 0',
              padding: '2rem 1rem',
            },
          }}
        >
          <WalletProfile closeDropdown={closeDropdown} />
        </Drawer>
      )}
    </div>
  )
}
