import { memo, useCallback, useState } from 'react'
import { Container, Grid, Stack, Button, InputBase, Typography } from '@mui/material'
import SubscribeImage from '@/assets/subscribe-bg-image.webp'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import { isValidEmail } from '@/utils/helpers'
import MessageDialog from './MessageDialog'
import { createSubscribe } from '@/root/src/utils/apis/subscribe'
import useToastMessage from '@/hooks/use-toast-message'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'
import withNoSsr from '@/components/shared/with-no-ssr'

const FormEmailSubscribe = (props: {
  email: string
  onChangeEmail: (e: any) => void
  handleRegister: () => void
}) => {
  const { email, onChangeEmail, handleRegister } = props
  const trans = useTrans()

  return (
      <Stack
        className="bg-ps-primary px-4 py-8 rounded-2xl mb-10 sm:bg-transparent sm:mb-6 md:my-0"
        gap={2}
      >
        <Typography className="font-semibold text-3xl sm:text-4xl text-white">
          {trans.subscribe.newsletters}
        </Typography>
        <Typography className="text-base sm:text-lg text-white/80">
          {trans.subscribe.description}
        </Typography>
        <Stack
          className="w-full sm:max-w-sm rounded-3xl border border-solid border-white px-3 py-2 sm:p-[2px]"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <InputBase
            fullWidth
            className="border-none text-white ml-1 sm:ml-4"
            placeholder={trans.subscribe.email}
            value={email}
            onChange={onChangeEmail}
            data-testid="email-input"
          />
          <Button
            size="small"
            onClick={handleRegister}
            className="normal-case font-bold text-ps-secondary w-fit rounded-xl sm:w-36 sm:rounded-3xl sm:p-3 sm:bg-ps-primary"
          >
            {trans.subscribe.register}
          </Button>
        </Stack>
      </Stack>
  )
}

function SubscribeEmail() {
  const [email, setEmail] = useState('')
  const [openNotice, setOpenNotice] = useState(false)
  const trans = useTrans()
  const toast = useToastMessage()
  const isMobile = useIsMobileDevice()

  const onChangeEmail = useCallback((e: any) => {
    setEmail(e.target.value)
  }, [])

  const handleRegister = useCallback(() => {
    if (!isValidEmail(email)) {
      toast.showMessage({ message: trans.subscribe.confirm_warning, type: 'error' })
      return
    }
    createSubscribe(email)
      .then((result) => {
        if (result.success) {
          setOpenNotice(true)
        } else {
          toast.showMessage({ message: trans.subscribe.confirm_error, type: 'error' })
        }
      })
  }, [email, toast, trans])

  return (
    <Container className='pt-20'>
      {isMobile ? (
        <FormEmailSubscribe
          email={email}
          onChangeEmail={onChangeEmail}
          handleRegister={handleRegister}
        />
      ) : (
        <Grid
          container
          className="bg-gradient-to-r from-ps-primary/[0.95]  deg px-4 rounded-2xl mb-10"
        >
          <Grid item sm={6} className="flex items-center">
            <FormEmailSubscribe
              email={email}
              onChangeEmail={onChangeEmail}
              handleRegister={handleRegister}
            />
          </Grid>
          <Grid item sm={6} justifyContent="end" className="flex pt-8">
            <Image src={SubscribeImage} alt="Subscribe image" />
          </Grid>
        </Grid>
      )}

      <MessageDialog
        openNotice={openNotice}
        setOpenNotice={setOpenNotice}
        title={trans.subscribe.confirm_title}
        description={trans.subscribe.confirm_description}
        confirmButtonText={trans.subscribe.confirm_done}
      />
    </Container>
  )
}

const SubscribeEmailWithMemo = memo(withNoSsr(SubscribeEmail))
SubscribeEmailWithMemo.displayName = 'SubscribeEmailView'
export default SubscribeEmailWithMemo
