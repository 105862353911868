import { memo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import PropEasyLogo from '@/components/base/PropEasyLogo'

function FooterPropeasy() {
  const trans = useTrans()
  return (
    <Stack data-testid="social-links-wrapper" direction="column" spacing={3}>
      <PropEasyLogo />
      <Typography data-testid="footer-copyright" className="text-base not-italic text-white pr-0 md:pr-5">
        {trans.footer.description}
      </Typography>
    </Stack>
  )
}

export default memo(FooterPropeasy)
