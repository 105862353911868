import { IconName } from '@/utils/static-import-icon'

export const socials = (onlyIcon?: boolean) => [
  {
    name: 'Twitter',
    url: 'https://twitter.com/PropeasyGlobal',
    icon: IconName.SOCIAL_TWITTER,
    onlyIcon: onlyIcon,
  },
  {
    name: 'Telegram',
    url: 'https://t.me/Propeasy',
    icon: IconName.SOCIAL_TELEGRAM,
    onlyIcon: onlyIcon,
  },
]
