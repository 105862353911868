import 'react-lazy-load-image-component/src/effects/blur.css'
import { Stack, Box } from '@mui/material'
import Footer from './footer'
import Header from './header'

export const MainLayout = ({ children }: HocProps) => {
  return (
    <Stack className="relative w-full min-h-screen h-screen overflow-hidden">
      <Header />
      <Box className="bg-ps-main flex-1 overflow-auto relative">
        {children}
        <Footer />
      </Box>
    </Stack>
  )
}
