import { useEffect } from 'react'
import { useWalletModal } from './useWalletModal'
import { DemonWalletName } from '../adapters'
import { useWallet } from '@solana/wallet-adapter-react'

export const useAutoConnect = () => {
  const { visible } = useWalletModal()
  const { select } = useWallet()

  useEffect(() => {
    if (
      !visible &&
      // @ts-ignore
      window.demon?.autoConnect &&
      localStorage.getItem('walletName') !== DemonWalletName
    ) {
      if (window.demon) {
        // @ts-ignore
        window.demon.autoConnect = false
      }
      select(DemonWalletName)
    }
  }, [visible, select])
}
