import React from 'react'
import { Box } from '@mui/material'
import { isBeta } from '@/utils/helpers'

const NetworkBadge = () => {
  if (!isBeta) return <></>

  return (
    <Box className="flex items-center p-2 bg-[#21D969] rounded-lg font-medium">
      Test
    </Box>
  )
}

export default NetworkBadge
