import { memo } from 'react'
import { Stack, Divider, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PropEasyWalletButton from './PropEasyWalletButton'
import LanguageSwitch from '@/layouts/components/LanguageSwitch'
import NetworkBadge from '@/layouts/components/NetworkBadge'
import { useCypressWalletConnected } from '@/hooks/use-cypress-wallet-connected'

interface PropEasyActionProps {
  isMobile: boolean
  handleClickMenu: () => void
}

const PropEasyAction = (props: PropEasyActionProps) => {
  const { isMobile, handleClickMenu } = props

  useCypressWalletConnected()

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      className="sm:w-fit"
    >
      <NetworkBadge />
      <PropEasyWalletButton />
      <LanguageSwitch />
      {isMobile && (
        <>
          <Divider orientation="vertical" flexItem className="border-white/25 mx-2 md:mx-4" />
          <IconButton
            className="bg-white/25 hover:bg-[#27536B] text-white mr-2 rounded-lg p-3/2"
            aria-label="open menu"
            edge="start"
            onClick={handleClickMenu}
          >
            <MenuIcon fontSize="medium" />
          </IconButton>
        </>
      )}
    </Stack>
  )
}

export default memo(PropEasyAction)
