import { Stack, Typography } from '@mui/material'
import { useWalletDetail, useWalletModal } from '../hooks'
import { TooltipLabel } from '@/components/shared/tooltip-label'
import WalletAddress from './WalletAddress'

function WalletReferral() {
  const { trans } = useWalletModal()
  const walletDetail = useWalletDetail()
  return (
    <Stack gap={1}>
      <Typography className="text-sm text-[#000]">{trans.your_referrer}</Typography>
      {walletDetail.connected && !!walletDetail.referral ? (
        <WalletAddress address={walletDetail.referral.toBase58()} />
      ) : (
        <Typography className="font-semibold text-[#000] flex items-center" component="div">
          {trans.no_referrer} <TooltipLabel content={trans.tooltip_referrer} />
        </Typography>
      )}
    </Stack>
  )
}

export default WalletReferral
