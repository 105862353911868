import React, { SyntheticEvent, memo, useCallback, useMemo } from 'react'
import { Stack, Typography, Dialog, Box } from '@mui/material'
import Image, { StaticImageData } from 'next/image'
import WalletConnectImage from '@/assets/wallet-connect-help.png'
import PropEasyButton from './PropEasyButton'

interface MessageDialogProps {
  openNotice: boolean
  setOpenNotice: (open: boolean) => void
  title?: string
  description: string | JSX.Element
  confirmButtonText: string
  confirmButtonRounded?: boolean
  confirmButtonBasic?: boolean
  confirmButtonClick?: () => void
  icon?: StaticImageData
  // TODO: Wrong use of the `onClose` with confirm action on this dialog,
  // lead to unexpected behavior, this prop use to prevent that on some dialog,
  // need to refactor this later
  preventBackdropTriggerOnClose?: boolean
}
function MessageDialog(props: MessageDialogProps) {
  const {
    openNotice,
    setOpenNotice,
    title,
    description,
    confirmButtonText,
    confirmButtonRounded,
    confirmButtonBasic,
    confirmButtonClick,
    icon,
    preventBackdropTriggerOnClose,
  } = props
  const msg = useMemo(
    () =>
      typeof description === 'string' ? (
        <Typography className="text-sm text-[#000000]/[0.72] text-center">{description}</Typography>
      ) : (
        description
      ),
    [description],
  )
  const handleCloseNotice = useCallback((_: SyntheticEvent, reason: string) => {
    setOpenNotice(false)
    // TODO: Wrong use of the `onClose` with confirm action on this dialog,
    // lead to unexpected behavior, this prop use to prevent that on some dialog,
    // need to refactor this later
    if (confirmButtonClick && (!preventBackdropTriggerOnClose || reason === undefined)) confirmButtonClick()
  }, [confirmButtonClick, preventBackdropTriggerOnClose, setOpenNotice])

  return (
    <Dialog
      data-testid="message-dialog"
      open={openNotice}
      onClose={handleCloseNotice}
      maxWidth="xs"
      className="z-[2023]"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#ffffff',
          borderRadius: '1rem',
        },
      }}
    >
      <Stack alignItems="center" className="p-4" gap={2}>
        <Image src={icon ? icon : WalletConnectImage} height={120} alt="wallet connect help icon" />
        {title && <Typography className="text-xl font-bold text-[#31393E] text-center">{title}</Typography>}
        <Box>{msg}</Box>
        <PropEasyButton
          text={confirmButtonText}
          handleClick={handleCloseNotice}
          rounded={confirmButtonRounded}
          basic={confirmButtonBasic}
        />
      </Stack>
    </Dialog>
  )
}

export default memo(MessageDialog)
