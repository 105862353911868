import { isMobileDevice } from '@/wallet/helpers'
import { useMediaQuery, useTheme } from '@mui/material'

export const useIsMobileDevice = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const mobileDetected = isMobileDevice

  return mobileDetected || isMobileScreen
}
