import type { FC, MouseEvent } from 'react'
import React, { useCallback } from 'react'

import { useWalletModal } from '../hooks'
import type { ButtonProps } from './Button'
import { Button } from './Button'
import { useAutoConnect } from '../hooks/use-auto-connect'

export const WalletModalButton: FC<ButtonProps> = ({ onClick, children, ...props }) => {
  const { visible, setVisible, trans } = useWalletModal()

  useAutoConnect()

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) onClick(event)
      if (!event.defaultPrevented) {
        setVisible(!visible)
      }
    },
    [onClick, setVisible, visible],
  )

  return (
    <Button className="wallet-adapter-button-trigger" onClick={handleClick} {...props}>
      {children || trans.connect_wallet}
    </Button>
  )
}
