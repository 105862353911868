import { memo } from 'react'
import { WalletMultiButton as DemonWalletConnect } from '@/wallet/components'

function PropEasyWalletButton() {
  return (
    <DemonWalletConnect className="px-4 py-2 mr-2 bg-[#2B5B74] hover:bg-[#27536B] rounded-[1.5rem] border-solid border border-[#4E768B] text-sm font-bold text-ps-secondary normal-case" />
  )
}

export default memo(PropEasyWalletButton)
