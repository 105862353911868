import { type ReactElement, useCallback } from 'react'
import { type OptionsObject, type SnackbarKey, useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const colors = {
  success: '#6EC05F',
  info: '#1271EC',
  warning: '#FED953',
  error: '#D60A2E',
}

type ToastMessageProps = {
  type?: 'success' | 'error' | 'warning' | 'info'
  message?: ReactElement | ReactElement[] | string
}

const useToastMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const showMessage = useCallback(
    (props: ToastMessageProps, options?: OptionsObject) => {
      const { message, type = 'success' } = props

      enqueueSnackbar(
        <Typography sx={{ color: colors[type] }} className="font-semibold mr-8">
          {message}
        </Typography>,
        {
          className: 'bg-white',
          variant: 'default',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          action: (key: SnackbarKey) => (
            <IconButton
              size="small"
              aria-label="close"
              color="primary"
              sx={{ p: 0.5 }}
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon />
            </IconButton>
          ),
          ...(options || {}),
        },
      )
    },
    [closeSnackbar, enqueueSnackbar],
  )

  return {
    showMessage,
  }
}

export default useToastMessage
