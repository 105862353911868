import { memo, useMemo } from 'react'
import { Box, List, ListItem, ListItemButton } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'
import withNoSsr from '@/components/shared/with-no-ssr'

function PropEasyMenu() {
  const trans = useTrans()
  const isMobile = useIsMobileDevice()

  const defaultMenus = useMemo(() => {
    return [
      {
        name: 'properties',
        link: '/properties',
        icon: isMobile ? IconName.MENU_PROPERTIES_BLACK_ICON : IconName.MENU_PROPERTIES_ICON,
      },
      {
        name: 'blog',
        link: '/blogs',
        icon: isMobile ? IconName.MENU_DOCUMENT_BLACK_ICON : IconName.MENU_DOCUMENT_ICON,
      },
    ]
  }, [isMobile])

  return (
    <Box component="nav" aria-label="Propeasy menu" sx={{ flexGrow: 1 }} className="p-4">
      <List role="menubar" className="flex flex-col sm:flex-row p-0 justify-center uppercase gap-3">
        {defaultMenus.map((menu, index) => (
          <ListItem key={index} role="none" className="sm:w-fit px-0 py-0">
            <ListItemButton
              role="menuitem"
              disableRipple={true}
              className="bg-[#EEF0F1] sm:bg-transparent rounded-lg font-semibold hover:text-ps-secondary p-4 gap-2"
              component="a"
              href={menu.link}
            >
              <Image src={staticImportIcon(menu.icon)} alt={menu.icon} width={20} />
              {trans.main_menu[menu.name]}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default memo(withNoSsr(PropEasyMenu))
