import React, { ReactElement } from 'react'
import NoSsr from './no-ssr'

const withNoSsr = (WrappedComponent: any) => {
  const ReturnedComponent = (props: any): ReactElement => (
    <NoSsr>
      <WrappedComponent {...props} />
    </NoSsr>
  )

  return ReturnedComponent
}

withNoSsr.displayName = 'withNoSsr'

export default withNoSsr
