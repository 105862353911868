import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Button, Divider, Stack } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '../hooks'
import { isBeta } from '@/utils/helpers'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'

import Image from 'next/image'
import WalletAddress from './WalletAddress'
import WalletShare from './WalletShare'
import WalletClaimBetaToken from './WalletClaimBetaToken'
import WalletReferral from './WalletReferral'

interface WalletProfileProps {
  closeDropdown: () => void
}

function WalletProfile(props: WalletProfileProps) {
  const { closeDropdown } = props
  const { disconnect } = useWallet()
  const { trans } = useWalletModal()
  const router = useRouter()
  const isMobileDevice = useIsMobileDevice()

  const handleHistoryClick = useCallback(() => {
    router.push({ pathname: '/transaction-history' })
  }, [router])

  return (
    <Stack gap={2} data-testid='wallet-profile'>
      <Stack direction="row" justifyContent="space-between">
        <WalletAddress isVerified />
        {isMobileDevice && (
          <Button
            disableRipple
            className="text-[#000] bg-[#F0F0EF] normal-case font-medium"
            onClick={closeDropdown}
          >
            {trans.close}
          </Button>
        )}
      </Stack>
      {isBeta && <WalletClaimBetaToken />}
      <Divider />
      <WalletReferral />
      <Divider />
      <WalletShare />
      <Button
        onClick={handleHistoryClick}
        disableRipple
        className="text-[#000] hover:bg-transparent hover:font-bold normal-case justify-start font-medium"
        startIcon={
          <Image
            src={staticImportIcon(IconName.HISTORY_ICON)}
            height={24}
            alt={IconName.HISTORY_ICON}
          />
        }
      >
        {trans.transaction_history}
      </Button>
      <Button
        disableRipple
        className="text-[#000] hover:bg-transparent hover:font-bold normal-case justify-start font-medium"
        onClick={disconnect}
        data-testid="disconnect-wallet-btn"
      >
        <Image
          className="inline mr-2"
          src={staticImportIcon(IconName.SIGN_OUT_ICON)}
          width={24}
          alt="disconnect-icon"
        />
        {trans.disconnect}
      </Button>
    </Stack>
  )
}

export default WalletProfile
