import { memo, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import FooterItem, { ItemProps } from './FooterItem'

function FooterCompany() {
  const trans = useTrans()
  const socials = useMemo<Array<ItemProps>>(() => {
    return [
      { name: trans.footer.company_terms, url: '/terms-of-service' },
      { name: trans.footer.company_policy, url: '/privacy-policy' },
    ]
  }, [trans])
  return (
    <Stack data-testid="social-links-wrapper" direction="column" spacing={3}>
      <Typography
        data-testid="footer-copyright"
        variant="h1"
        className="text-xl font-bold not-italic text-white"
      >
        {trans.footer.company_about}
      </Typography>
      <Stack gap={3}>
        {socials.map((item, index) => {
          return <FooterItem key={index} {...item} />
        })}
      </Stack>
    </Stack>
  )
}

export default memo(FooterCompany)
