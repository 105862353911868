import { useEffect } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { WalletName } from '@solana/wallet-adapter-base'
import { isRunningInCypress } from '@/wallet/index'

export const useCypressWalletConnected = () => {
  const { select } = useWallet()

  useEffect(() => {
    if (isRunningInCypress && (window as any).PRE_CONNECTED_WALLET) {
      select('E2E' as WalletName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
