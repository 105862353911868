import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import type { MouseEvent } from 'react'
import Image from 'next/image'
import { LanguageContext } from '@/providers/language'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

const COUNTRY_CODE_MAPPER = {
  vi: 'Tiếng Việt',
  en: 'English',
} as any

interface LanguageSwitchProps {
  dark?: boolean
}

const LanguageSwitch = (props: LanguageSwitchProps) => {
  const { dark } = props
  const { lang, handleChangeLanguage } = useContext(LanguageContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const iconName = dark ? IconName.GLOBAL_BLACK_ICON : IconName.GLOBAL_ICON

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton
        id="btn-preferences-settings"
        size="small"
        aria-controls={open ? 'menu-preferences-settings' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="text-zinc-500"
      >
        <Image className="inline" src={staticImportIcon(iconName)} height={32} alt={iconName} />
      </IconButton>
      <Menu
        id="menu-preferences-settings"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'btn-preferences-settings',
        }}
        sx={{
          '& .MuiPaper-root .MuiList-root': {
            backgroundColor: 'white',
            color: 'black',
            padding: 0,
            '& .Mui-selected': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
          },
        }}
      >
        {Object.keys(COUNTRY_CODE_MAPPER).map((code) => (
          <MenuItem
            key={code}
            selected={lang === code}
            onClick={() => {
              handleChangeLanguage(code)
              handleClose()
            }}
            data-testid={`btn-change-language-to-${code}`}
          >
            <Box className="w-[40px]">
              <Image src={staticImportIcon(IconName[`LANG_${code.toUpperCase()}_ICON` as keyof typeof IconName])} alt={code} width={32} height={22} />
            </Box>
            {COUNTRY_CODE_MAPPER[code]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitch
