import React, { memo, useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Paper, Stack, Divider, Typography } from '@mui/material'

import PropEasyLogo from '@/components/base/PropEasyLogo'
import PropEasyMenu from '@/components/base/PropEasyMenu'
import PropEasyWalletButton from '@/components/base/PropEasyWalletButton'
import useTrans from '@/hooks/useTrans'
import FooterItem, { ItemProps } from './FooterItem'
import { socials as dataSocials } from '@/constants/footer'
import { Box } from '@mui/system'

type MainMenuProps = {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

const MainMenu = (props: MainMenuProps) => {
  const { toggleDrawer } = props
  const trans = useTrans()
  const socials = useMemo<Array<ItemProps>>(() => {
    return dataSocials(true)
  }, [])

  return (
    <Stack
      className="bg-gradient-page h-full w-full"
      justifyContent="space-between"
      role="presentation"
    >
      <Paper elevation={0} className="bg-ps-primary rounded-none">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
          className="w-full p-3 border-solid border-b-2 border-b-slate-600-100"
        >
          <PropEasyLogo iconOnly />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className="sm:w-fit"
          >
            <PropEasyWalletButton />
            <Divider orientation="vertical" flexItem className="border-white/25 mx-2 md:mx-4" />
            <IconButton
              className="bg-white/25 text-white mr-2 rounded-lg p-1"
              aria-label="open menu"
              edge="start"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
      <Stack justifyContent="space-between" className="h-full bg-white">
        <PropEasyMenu />
        <Box>
          <Stack direction="row" justifyContent="center" gap={4} padding={3}>
            {socials.map((item, index) => {
              return <FooterItem key={index} {...item} />
            })}
          </Stack>
          <Typography
            data-testid="footer-copyright"
            variant="body2"
            className="text-base font-normal not-italic text-center py-5 text-black border-t border-solid border-[#C5C5C5]"
          >
            {trans.common.powered_by}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

export default memo(MainMenu)
