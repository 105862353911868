import { IconButton, Typography } from '@mui/material'
import HtmlTooltip from '../base/HtmlTooltip'
import Image from 'next/image'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'

export const TooltipLabel = ({ content }: { content: string }) => {
  return (
    <HtmlTooltip
      title={
        <Typography
          component="div"
          className="text-xs text-left"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      }
      arrow
    >
      <IconButton color="primary" className="rounded-full p-0 px-2" disableRipple data-testid='apy-button'>
        <Image
          className="inline"
          src={staticImportIcon(IconName.INFO_CIRCLE_ICON)}
          height={20}
          alt={IconName.INFO_CIRCLE_ICON}
        />
      </IconButton>
    </HtmlTooltip>
  )
}
