import { memo } from 'react'
import { Box, Container, Typography, Grid } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import FooterSocial from '../components/FooterSocial'
import FooterCompany from '../components/FooterCompany'
import FooterPropeasy from '../components/FooterPropeasy'
import FooterResources from '../components/FooterResources'
import SubscribeEmail from '@/components/base/SubscribeEmail'
import TrustpilotSummary from '@/components/shared/trustpilot/index'

const Footer = () => {
  const trans = useTrans()

  return (
    <>
      <SubscribeEmail />
      <TrustpilotSummary />
      <Box className="w-full bg-ps-dark-blue">
        <Container className="py-10">
          <Grid container spacing={{md: 2, xs: 4}}>
            <Grid item xs={12} sm={12} md={5}>
              <FooterPropeasy />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FooterCompany />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FooterResources />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FooterSocial />
            </Grid>
          </Grid>
        </Container>
        <Typography
          data-testid="footer-copyright"
          variant="body2"
          className="text-base font-normal not-italic text-center py-5 text-white border-t border-solid border-[#C5C5C5]"
        >
          {trans.common.powered_by}
        </Typography>
      </Box>
    </>
  )
}

export default memo(Footer)
