import 'moment/locale/vi'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { useCallback } from 'react'

import moment, { LocaleSpecifier } from 'moment'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Carousel } from 'react-responsive-carousel'

import { TRUSTPILOT_REVIEW_URL } from '@/constants/index'
import { useIsMobileDevice } from '@/hooks/use-is-mobile-device'
import useTrans from '@/hooks/useTrans'
import {
  IconName,
  staticImportIcon,
} from '@/utils/static-import-icon'
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import withNoSsr from '../with-no-ssr'
import REVIEW_DATA from './reviews.json'

const SCORED_BG_COLOR = '#219653'
const UNSCORED_BG_COLOR = '#D9D9D9'

interface StarStylingProps {
  starContainerClass: string
  starSize: number
}
interface TrustpilotStarProps extends StarStylingProps {
  starInteger: number
  starDecimal: number
  starIndex: number
}
interface TrustpilotRankProps extends StarStylingProps {
  totalStar: number
}

const ReviewCarouselArrow = ({
  clickHandler,
  label,
  arrow,
}: {
  clickHandler: () => void
  label: string
  arrow: number
}) => (
  <Box
    component="button"
    aria-label={label}
    className={`control-${arrow ? 'next' : 'prev'} absolute top-[45%] ${
      arrow === 0 ? 'left-0' : 'right-0'
    } px-2 py-3 mx-2 bg-[#EFEFEF8F] rounded-2xl z-10 opacity-40 hover:opacity-100`}
    onClick={clickHandler}
  >
    <Image
      src={staticImportIcon(arrow === 0 ? IconName.SLIDE_PREVIOUS : IconName.SLIDE_NEXT)}
      alt={IconName.SLIDE_PREVIOUS}
      height={30}
    />
  </Box>
)

// TODO: Move to a base carousel for text based component
const ReviewCarousel = styled(Carousel)(() => {
  const isMobile = useIsMobileDevice()
  const controlPosition = isMobile ? -35 : -40
  const sliderWrapperStyle = {
    '& .slider-wrapper': {
      paddingLeft: '25px',
    },
  }

  return {
    '& .carousel-slider': {
      overflow: 'visible !important',
      '& .control-prev': {
        left: `${controlPosition}px !important`,
      },
      '& .control-next': {
        right: `${controlPosition}px !important`,
      },
      '& p': {
        textAlign: 'left',
      },
      '& .trustpilot-star': {
        width: '20px !important',
        height: '20px !important',
      },
      '& .trustpilot-avatar': {
        width: '70px !important',
      },
      ...(!isMobile ? sliderWrapperStyle : {}),
    },
  }
})

const TrustpilotStar = ({
  starInteger,
  starDecimal,
  starIndex,
  starContainerClass,
  starSize,
}: TrustpilotStarProps) => {
  const currentStarNumber = starIndex + 1
  const decimalPercentage = starDecimal * 100
  let bgColor = SCORED_BG_COLOR
  let hasBgImage = false

  if (currentStarNumber === starInteger + 1 && starDecimal > 0) {
    bgColor = `linear-gradient(to right, ${SCORED_BG_COLOR} 0% ${decimalPercentage}%, ${UNSCORED_BG_COLOR} ${decimalPercentage}% 100%)`
    hasBgImage = true
  } else if (currentStarNumber > starInteger) {
    bgColor = UNSCORED_BG_COLOR
  }

  const bgProp = hasBgImage ? { backgroundImage: bgColor } : { backgroundColor: bgColor }

  return (
    <Box className={`trustpilot-star ${starContainerClass}`} sx={bgProp}>
      <Image src={staticImportIcon(IconName.TRUSTPILOT_STAR)} height={starSize} alt="trust-pilot" />
    </Box>
  )
}

const TrustpilotRank = ({ totalStar, starContainerClass, starSize }: TrustpilotRankProps) => {
  const starInteger = Math.trunc(totalStar)
  const starDecimal = parseFloat((totalStar - starInteger).toFixed(2))

  return (
    <Stack direction="row">
      {[...Array(5).keys()].map(
        idx => <TrustpilotStar
          key={idx}
          starInteger={starInteger}
          starDecimal={starDecimal}
          starIndex={idx}
          starContainerClass={starContainerClass}
          starSize={starSize}
        />,
      )}
    </Stack>
  )
}

const TrustpilotReview = () => {
  const { locale } = useRouter()
  const isMobile = useIsMobileDevice()
  const elapseTimeString = useCallback((dateString: string) => {
    const momentLocale = moment(dateString)
    momentLocale.locale(locale as LocaleSpecifier)
    return momentLocale.fromNow()
  }, [locale])
  const renderArrowPrev = useCallback(
    (clickHandler: () => void, _: boolean, label: string) => {
      return <ReviewCarouselArrow label={label} clickHandler={clickHandler} arrow={0} />
    },
    [],
  )
  const renderArrowNext = useCallback(
    (clickHandler: () => void, _: boolean, label: string) => {
      return <ReviewCarouselArrow label={label} clickHandler={clickHandler} arrow={1} />
    },
    [],
  )
  const reviews: any = (REVIEW_DATA.reviews as any)[locale ?? 'en']

  return (
    <Container className="pb-20 pt-7 px-7">
      <ReviewCarousel
        autoPlay
        centerMode={!isMobile}
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        centerSlidePercentage={35}
        interval={2500}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
      >
        {reviews.map((review: any, idx: number) => (
          <Link href={TRUSTPILOT_REVIEW_URL} color="inherit" className="no-underline" target="_blank" rel="noopener" key={idx}>
            <Box className="bg-white p-8 md:mr-3 h-[280px]">
              <Stack direction="column">
                <Typography className="text-xs font-light text-[#6A6A6A] mb-2">
                  {elapseTimeString(review.time)}
                </Typography>
                <Stack direction="row" alignItems="center" className='mb-1.5'>
                  {review.avatar && (
                    <Box className="rounded-full overflow-hidden mr-3.5 trustpilot-avatar">
                      <Image src={review.avatar} alt='trust-pilot-review-avatar' width={70} height={70} />
                    </Box>
                  )}
                  <Stack direction="column">
                    <Typography className="mb-2 text-sm font-semibold">{review.name}</Typography>
                    <TrustpilotRank totalStar={review.star} starContainerClass="p-[3.5px] mr-1" starSize={16} />
                  </Stack>
                </Stack>
                <Stack direction="column">
                  <Typography className="mb-2 text-sm font-bold">
                    {review.title}
                  </Typography>
                  <Typography className="overflow-hidden text-sm font-light whitespace-pre-line font-roboto text-ellipsis" maxHeight={100}>
                    {review.content}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        ))}
      </ReviewCarousel>
    </Container>
  )
}

const TrustpilotSummary = () => {
  const trans = useTrans()
  const isMobile = useIsMobileDevice()

  return (
    <>
      <Container className="px-20 pt-6 pb-2">
        <Stack alignItems="center" justifyContent="center" className="pb-6 sm:flex-col md:flex-row">
          <Link href={TRUSTPILOT_REVIEW_URL} color="inherit" className="no-underline" target="_blank" rel="noopener">
            <Box className="px-6 py-4">
              <Image src={staticImportIcon(IconName.TRUSTPILOT_LOGO)} alt="trust-pilot-logo" width={isMobile ? 280 : 160} />
            </Box>
            <TrustpilotRank totalStar={REVIEW_DATA.totalStar} starContainerClass="p-2 mr-1" starSize={30} />
          </Link>
        </Stack>
        <Stack alignItems="center" justifyContent="center" className="sm:flex-col md:flex-row">
          {!!REVIEW_DATA.totalReview && (
            <Typography className='text-base text-[#6A6A6A] font-medium mr-4 max-sm:mb-4'>
              {trans.trustpilot.overview(REVIEW_DATA.totalStar, REVIEW_DATA.totalReview)}
            </Typography>
          )}
          <Button
            disableRipple
            className="px-6 py-3 text-base font-semibold normal-case border border-solid rounded-full bg-ps-main text-ps-primary border-ps-primary hover:bg-ps-dark-blue hover:text-ps-main"
            href={TRUSTPILOT_REVIEW_URL}
            target='_blank'
          >
            {trans.trustpilot.rateNow}
          </Button>
        </Stack>
      </Container>
      <TrustpilotReview />
    </>
  )
}

export default withNoSsr(TrustpilotSummary)
