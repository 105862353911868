import { memo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import FooterItem from './FooterItem'
import { socials as dataSocials } from '@/constants/footer'

function FooterSocial() {
  const trans = useTrans()
  const socials = dataSocials()
  return (
    <Stack data-testid="social-links-wrapper" direction="column" spacing={3}>
      <Typography
        data-testid="footer-copyright"
        variant="h1"
        className="text-xl font-bold not-italic text-white"
      >
        {trans.footer.social_follow_us}
      </Typography>
      <Stack gap={3}>
        {socials.map((item, index) => {
          return <FooterItem key={index} {...item} />
        })}
      </Stack>
    </Stack>
  )
}

export default memo(FooterSocial)
